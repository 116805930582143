define("discourse/plugins/discourse-solved-reminders-plugin/discourse/initializers/extend-for-solved-reminders", ["exports", "@ember/object", "discourse/lib/api"], function (_exports, _object, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.11.1", api => {
    api.modifyClass("controller:preferences/notifications", Superclass => class extends Superclass {
      save() {
        this.saveAttrNames.push("custom_fields");
        super.save(...arguments);
      }
      static #_ = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    });
  });
});